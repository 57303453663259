
import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { mdiTelevisionClassic } from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component({
    components: {
        BackButton,
    },
    metaInfo() {
        const title = "DStv Subscription Price Update - November 2022";
        const description =
            "DStv subscription prices on nyangapay just got cheaper due to the falling exchange rate between NGN and XAF.";
        return {
            title,
            meta: [
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class DstvPackageChecker extends Vue {
    tvIcon: string = mdiTelevisionClassic;

    percentage(oldPrice: number, newPrice: number) {
        return (
            (
                ((newPrice - oldPrice) / parseFloat(oldPrice.toString())) *
                100
            ).toFixed(0) + "%"
        );
    }

    mounted(): void {
        addAnalyticsEvent("price_lock_banner_clicked");
    }
}
